import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AdminService } from '../../../../services/admin.service';
import * as validator from '../../../../validations/admin/brand/add-brand-validation'; //validation
import { DomSanitizer } from '@angular/platform-browser';
import * as env from '../../../../globals/env';
import * as _ from 'lodash';

import Swal from 'sweetalert2';
//import { timeout } from 'rxjs/operator/timeout';

declare var $: any;

@Component({
  selector: 'app-brand-add',
  templateUrl: './brand-add.component.html',
  styleUrls: ['./brand-add.component.scss']
})
export class BrandAddComponent implements OnInit {

  public name: any = '';
  public domain: String;
  public buylink: any = '';
  public price: any;
  public bio: any;
  public comments: any;
  public category: any;
  public brand_status: any;
  public total_letter: any;
  public extension: any;
  public leaseprice: any;
  public video: any;
  //public by_style: any;  
  public keyword: any;
  public audiofile: any;
  public videofile: any;
  public zipfile: any;
  public bannerImage: any;
  public TagList: any = [];
  public CategoryList: any = [];
  public certficateImage: any;
  public certiimages: any;
  isSubmitBtnDisabled: boolean = false;
  public first_name_domain: number = 0;
  public display_order: number = 100;
  images = [];



  fileToUpload: File = null;
  myBrandFiles: string[] = [];

  public validationErrors: any = {}; // Error object

  constructor(public adminService: AdminService,
    private sanitization: DomSanitizer,
    private router: Router) { }

  ngOnInit() {
    this.adminService.getActiveTagList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        $('#select3').select2({
          placeholder: "Select keyword",
          width: '100%',
          multiple: "multiple",
          addClass: "c-select c-select--multiple",
          data: function () { return { results: res.data }; }
        });
        this.TagList = res.data;
      }
    });

    this.adminService.getActiveCategoryList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        $('#select4').select2({
          placeholder: "Select category",
          multiple: "multiple",
          width: '100%',
          data: function () { return { results: res.data }; }
        });
        this.CategoryList = res.data;
      }
    });

    var bstatus = [{ id: 'available', text: "Available" }, { id: 'sold', text: "Sold" }, { id: 'onlease', text: "On Lease" }, { id: 'inactive', text: "Inactive" }];

    $('#select1').select2({
      placeholder: "Select brand status",
      width: '100%',
      data: function () { return { results: bstatus }; }
    });
  }





  addBrandDetails() {

    let btandObj = {
      name: this.name,
      domain: this.domain,
      price: this.price,
      extension: this.extension,
      leaseprice: this.leaseprice,
      total_letter: this.total_letter,
      category: $('#select4').val(),
      keyword: $('#select3').val(),
      brand_status: $('#select1').val(),
      zipfile: this.zipfile,
      display_order: this.display_order,
      audiofile: this.audiofile
    };

    let validate = validator.validateBrandDetails(btandObj);

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {};

      let finalbrandObj = {
        name: this.name,
        domain: this.domain.toLowerCase(),
        buylink: this.buylink,
        price: this.price,
        leaseprice: this.leaseprice,
        extension: this.extension.toLowerCase(),
        bio: this.bio,
        comments: this.comments,
        total_letter: this.total_letter,
        video: this.video,
        //  by_style: this.by_style,
        first_name_domain: this.first_name_domain,
        category: $('#select4').val(),
        keyword: $('#select3').val(),
        brand_status: $('#select1').val(),
        display_order: this.display_order
      };

      const formData: any = new FormData();
      formData.append("data", JSON.stringify(finalbrandObj));
      if (this.bannerImage && this.bannerImage.length > 0) {
        for (var i = 0; i < this.bannerImage.length; i++) {
          formData.append("uploads[]", this.bannerImage[i], this.bannerImage[i].name);
        }
      }
      if (this.videofile) {
        formData.append("video", this.videofile, this.videofile.name);
      }
      if (this.audiofile) {
        formData.append("audio", this.audiofile, this.audiofile.name);
      }
      if (this.zipfile) {
        formData.append("zip", this.zipfile, this.zipfile.name);
      }
      if (this.certficateImage) {
        formData.append("certificate", this.certficateImage, this.certficateImage.name);
      }

      $('.customLoader').fadeIn();
      this.isSubmitBtnDisabled = true;
      this.adminService.addBrand(formData).subscribe((data: any) => {
        $('.customLoader').fadeOut();
        //this.isSubmitBtnDisabled = true;
        var res = data.response;
        if (data.status) {
          this.router.navigate(['/admin/brand/view']);
          Swal.fire('Success!', res.msg, 'success');
        } else {
          this.isSubmitBtnDisabled = false;
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }

  }

  onFileChange(event) {
    this.bannerImage = event.target.files;
    if (event.target.files && event.target.files[0]) {

      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.images.push(event.target.result);
        }
        reader.readAsDataURL(event.target.files[i]);

      }

    }

  }

  onZipChange(event) {
    this.zipfile = event.target.files.item(0);
  }

  onVideoChange(event) {
    this.videofile = event.target.files.item(0);
  }

  onAudioChange(event) {
    this.audiofile = event.target.files.item(0);
  }


  onCertificateFileChange(event: any) {

    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.certiimages = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
      this.certficateImage = event.target.files[0];
    }
  }

  updateFirstName(isChecked: boolean) {
    if (isChecked) {
      this.first_name_domain = 1;
    } else {
      this.first_name_domain = 0;
    }
  }



}
