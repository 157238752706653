import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute, Params } from '@angular/router';
import { AdminService } from '../../../../services/admin.service';
import * as validator from '../../../../validations/admin/brand/add-brand-validation'; //validation
import { DomSanitizer } from '@angular/platform-browser';
import * as env from '../../../../globals/env';
import * as _ from 'lodash';

import Swal from 'sweetalert2';
//import { timeout } from 'rxjs/operator/timeout';

declare var $: any;

@Component({
  selector: 'app-brand-edit',
  templateUrl: './brand-edit.component.html',
  styleUrls: ['./brand-edit.component.scss']
})
export class BrandEditComponent implements OnInit {

  public brand: any;
  public brandId: any;
  public name: any = '';
  public domain: String;
  public buylink: any = '';
  public price: any;
  public bio: any;
  public comments: any;
  public category: any;
  public keyword: any;
  public brand_status: any;
  public total_letter: any;
  public extension: any;
  public leaseprice: any;
  // public by_style: any;
  public audiofile: any;
  public videofile: any;
  public bannerImage: any;
  public certficateImage: any;
  public TagList: any = [];
  public CategoryList: any = [];
  isSubmitBtnDisabled: boolean = false;
  public zipfile: any;
  public audio: any;
  public video: any;
  public editzip: any;
  public editvideo: any;
  public editaudio: any;
  public editcertificate: any;
  public first_name_domain: number = 0;
  public display_order: number = 100;
  images = [];
  editimages = [];
  deleteimages = [];

  fileToUpload: File = null;
  myBrandFiles: string[] = [];

  public validationErrors: any = {}; // Error object

  constructor(public adminService: AdminService,
    private sanitization: DomSanitizer,
    private router: Router,
    public activatedRoute: ActivatedRoute) { }

  ngOnInit() {

    this.adminService.getActiveTagList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        $('#select3').select2({
          placeholder: "Select keyword",
          width: '100%',
          multiple: "multiple",
          addClass: "c-select c-select--multiple",
          data: function () { return { results: res.data }; }
        });
        this.TagList = res.data;
      }
    });

    this.adminService.getActiveCategoryList().subscribe((data: any) => {
      var res = data.response;
      if (data.status) {
        $('#select4').select2({
          placeholder: "Select category",
          multiple: "multiple",
          width: '100%',
          data: function () { return { results: res.data }; }
        });
        this.CategoryList = res.data;
      }
    });

    var bstatus = [{ id: 'available', text: "Available" }, { id: 'sold', text: "Sold" }, { id: 'onlease', text: "On Lease" }, { id: 'inactive', text: "Inactive" }];

    $('#select1').select2({
      placeholder: "Select brand status",
      width: '100%',
      data: function () { return { results: bstatus }; }
    });

    this.activatedRoute.params.subscribe((params: Params) => {
      this.brandId = params['id'];

      let brandObj = {
        brand_id: this.brandId
      }
      $('#smallLoader').show();
      this.adminService.getBrandDetails(brandObj).subscribe((data: any) => {
        var res = data.response;
        if (data.status) {

          this.brand = res.data;
          $('#select4').val(this.brand.category).change();
          $('#select3').val(this.brand.keyword).change();
          $('#select1').val(this.brand.brand_status).change();
          this.name = this.brand.name;
          this.domain = this.brand.domain;
          this.buylink = this.brand.buylink;
          this.price = this.brand.price;
          this.bio = this.brand.bio;
          this.comments = this.brand.comments;
          this.leaseprice = this.brand.leaseprice;
          this.extension = this.brand.extension;
          this.total_letter = this.brand.total_letter;
          this.display_order = this.brand.display_order;
          this.video = this.brand.video;
          // this.by_style = this.brand.by_style;
          this.first_name_domain = this.brand.first_name_domain;
          this.brand_status = this.brand.brand_status;

          if (this.brand.images && this.brand.images.length > 0) {
            this.editimages = this.brand.images;
          }

          if (this.brand.audio && this.brand.audio.length > 0) {
            // this.audio = this.brand.audio[0].name;
            this.editaudio = this.brand.audio[0].audioname;
          }

          if (this.brand.videofile && this.brand.videofile.length > 0) {
            //this.video = this.brand.video[0].name;
            this.editvideo = this.brand.videofile[0].videoname;
          }

          if (this.brand.zip && this.brand.zip.length > 0) {
            // this.editzip = this.brand.zip[0].name;
            this.editzip = this.brand.zip[0].zipname;
          }

          if (this.brand.certificate && this.brand.certificate.length > 0) {
            // this.editzip = this.brand.zip[0].name;
            this.editcertificate = this.brand.certificate[0].name;
          }

          $('#smallLoader').hide();

        } else {
          this.router.navigate(['/admin/brand/view']);
        }
      });
    });

  }

  deleteImage(event) {
    $("#" + event.brand_asset_id).remove();
    this.deleteimages.push(event.imageName)
  }

  editBrandDetails() {

    let btandObj = {
      name: this.name,
      domain: this.domain,
      price: this.price,
      extension: this.extension,
      leaseprice: this.leaseprice,
      total_letter: this.total_letter,
      first_name_domain: this.first_name_domain,
      category: $('#select4').val(),
      keyword: $('#select3').val(),
      brand_status: $('#select1').val(),
      zipfile: this.zipfile,
      audiofile: this.audiofile,
      display_order: this.display_order
    };

    let validate = validator.validateBrandDetails(btandObj);

    if (!validate.isValid) {
      this.validationErrors = validate.errors;
      return false;
    } else {
      this.validationErrors = {};

      let finalbrandObj = {
        name: this.name,
        domain: this.domain.toLowerCase(),
        buylink: this.buylink,
        price: this.price,
        leaseprice: this.leaseprice,
        extension: this.extension.toLowerCase(),
        video: this.video,
        total_letter: this.total_letter,
        //  by_style: this.by_style,
        bio: this.bio,
        comments: this.comments,
        deleteimages: this.deleteimages,
        first_name_domain: this.first_name_domain,
        category: $('#select4').val(),
        keyword: $('#select3').val(),
        brand_status: $('#select1').val(),
        brandId: this.brandId,
        display_order: this.display_order
      };

      this.validationErrors = {};
      const formData: any = new FormData();
      formData.append("data", JSON.stringify(finalbrandObj));
      if (this.bannerImage && this.bannerImage.length > 0) {
        for (var i = 0; i < this.bannerImage.length; i++) {
          formData.append("uploads[]", this.bannerImage[i], this.bannerImage[i].name);
        }
      }
      if (this.videofile) {
        formData.append("video", this.videofile, this.videofile.name);
      }
      if (this.audiofile) {
        formData.append("audio", this.audiofile, this.audiofile.name);
      }

      if (this.zipfile) {
        formData.append("zip", this.zipfile, this.zipfile.name);
      }

      if (this.certficateImage) {
        formData.append("certificate", this.certficateImage, this.certficateImage.name);
      }

      $('#smallLoader').show();
      this.isSubmitBtnDisabled = true;
      this.adminService.editBrand(formData).subscribe((data: any) => {
        $('#smallLoader').hide();
        var res = data.response;
        if (data.status) {
          this.isSubmitBtnDisabled = true;
          this.router.navigate(['/admin/brand/view']);
          Swal.fire('Success!', res.msg, 'success');
        } else {
          this.isSubmitBtnDisabled = false;
          Swal.fire('Oops...', res.msg, 'error')
        }
      });
    }
  }

  onZipChange(event) {
    this.zipfile = event.target.files.item(0);
  }

  onFileChange(event) {
    this.bannerImage = event.target.files;
    if (event.target.files && event.target.files[0]) {
      var filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        var reader = new FileReader();
        reader.onload = (event: any) => {
          this.images.push(event.target.result);
        }
        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }

  onCertificateFileChange(event: any) {
    if (event.target.files && event.target.files[0]) {
      var reader = new FileReader();

      reader.onload = (event: any) => {
        this.editcertificate = event.target.result;
      }

      reader.readAsDataURL(event.target.files[0]);
      this.certficateImage = event.target.files[0];
    }

  }


  onVideoChange(event) {
    this.videofile = event.target.files.item(0);
  }

  onAudioChange(event) {
    this.audiofile = event.target.files.item(0);
  }

  updateFirstName(isChecked: boolean) {
    if (isChecked) {
      this.first_name_domain = 1;
    } else {
      this.first_name_domain = 0;
    }
  }


}
